<template>
  <div class="lay-out">
    <NavBar />
    <div class="content">
      <slot></slot>
    </div>
    <FooterApp />
  </div>
</template>
<script>
import NavBar from "./NavBar.vue";
import FooterApp from "./FooterApp.vue";
export default {
  components: {
    NavBar,
    FooterApp,
  },
};
</script>
