<template>
  <div class="home">
    <div v-if="loading">Loading...</div>
    <div v-if="error" class="error">{{ error }}</div>
    <SliderHome
      v-if="datahome"
      :datahome="datahome"
      @update-data="handleUpdateData"
    />
    <FormHome
      v-if="datahome"
      :datahome="datahome"
      :product_id="datahome.id"
      :total_price="totalPrice"
      :quantity="quantity"
    />
  </div>
</template>

<script>
import SliderHome from "../components/global/home/SliderHome.vue";
import FormHome from "../components/global/home/FormHome.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    SliderHome,
    FormHome,
  },
  data() {
    return {
      datahome: null,
      error: null,
      loading: true,
      totalPrice: 0, // To store the total price
      quantity: 1, // To store the quantity
    };
  },
  async created() {
    this.loading = true;
    try {
      const response = await axios.get(
        `/get-products/${this.$route.params.slug}`
      );
      this.datahome = response.data.data;
    } catch (error) {
      this.error =
        error.response?.data?.message ||
        error.message ||
        "An error occurred while fetching data.";
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleUpdateData(newData) {
      this.totalPrice = newData.total_price;
      this.quantity = newData.quantity;
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-weight: bold;
}
</style>
