<template>
  <lay-out>
    <router-view />
  </lay-out>
</template>
<script>
import LayOut from "./components/global/LayOut.vue";
export default {
  components: {
    LayOut,
  },
};
</script>
<style>
@import url("./assets/scss/_mainstyle.scss");
</style>
