<template>
  <div class="form">
    <h4>To purchase the product</h4>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <div class="all">
          <div class="all-one">
            <label for="nameInput">Name</label>
            <input
              type="text"
              class="form-control"
              id="nameInput"
              v-model="name"
            />
          </div>
          <div class="all-two">
            <label for="emailInput">Email address</label>
            <input
              type="email"
              class="form-control"
              id="emailInput"
              placeholder="5JtVJ@example.com"
              v-model="email"
            />
          </div>
        </div>

        <div class="allo">
          <div class="all-three">
            <label for="phoneInput">Phone number</label>
            <input
              type="phone"
              class="form-control"
              id="phoneInput"
              v-model="phone"
            />
          </div>
          <div class="all-four">
            <label for="addressInput">Address</label>
            <input
              type="text"
              class="form-control"
              id="addressInput"
              v-model="address"
            />
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary">Buy</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import swl from "sweetalert2";

export default {
  name: "FormHome",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      address: "", // Corrected typo from 'adress' to 'address'
      dataform: null,
    };
  },
  props: {
    datahome: {
      type: Object,
      required: true,
    },
    product_id: {
      type: Number,
      required: true,
    },
    total_price: {
      type: Number,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async submitForm() {
      console.log(this.total_price);
      console.log(this.quantity);

      try {
        const response = await axios.post("/store-payment-info", {
          name: this.name,
          email: this.email,
          phone: this.phone,
          address: this.address,
          product_id: this.product_id,
          total_price: this.total_price,
          count: this.quantity,
        });

        console.log("Form submitted successfully:", response.data);

        // Show SweetAlert2 success message
        swl.fire({
          title: "Success!",
          text: "Your form has been submitted successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("An error occurred while submitting the form:", error);

        // Show SweetAlert2 error message
        swl.fire({
          title: "Error!",
          text: "There was a problem submitting your form. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },
  },
};
</script>

<style scoped></style>
