<template>
  <div class="slider-home">
    <div class="product-img">
      <img :src="datahome?.image" alt="" />
      <hr />
      <div class="details">
        <h3>product details:</h3>
        <h4><span>name:</span> {{ datahome?.name }}</h4>
        <h4><span>price:</span> {{ datahome?.price }} $</h4>
        <div
          class="catagory"
          v-for="catagory in datahome?.categories"
          :key="catagory"
        >
          <h4><span>catagory:</span>{{ catagory }}</h4>
        </div>
        <h4 class="num">
          <span>number of devices:</span>
          <div class="incres">
            <span @click="increment">+</span>
            <p>{{ quantity }}</p>
            <span @click="decrement">-</span>
          </div>
        </h4>
        <h4><span>Total Price : </span>{{ total_Price }} $</h4>
      </div>
    </div>
    <div class="dd">
      <swiper
        :style="{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }"
        :spaceBetween="10"
        :navigation="true"
        :thumbs="{ swiper: thumbsSwiper }"
        :modules="modules"
        class="mySwiper2"
      >
        <swiper-slide v-for="img in datahome?.images" :key="img">
          <img :src="img.image"
        /></swiper-slide>
      </swiper>
      <swiper
        @swiper="setThumbsSwiper"
        :spaceBetween="10"
        :slidesPerView="4"
        :freeMode="true"
        :watchSlidesProgress="true"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="img in datahome?.images" :key="img"
          ><img :src="img.image"
        /></swiper-slide>
      </swiper>
    </div>
  </div>
  <div class="discraption">
    <h4>
      <span>discription:</span><br />
      {{ datahome?.description }}
    </h4>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import swl from "sweetalert2";

export default {
  props: {
    datahome: {
      type: Array,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbsSwiper: null,
      quantity: 1,
      alldata: [],
      price: this.datahome?.price || 0,
      total_Price: this.price,
    };
  },
  methods: {
    increment() {
      if (this.quantity < this.datahome.count) {
        this.quantity += 1;
        this.total_Price = this.quantity * this.price;
        this.$emit("update-data", {
          total_price: this.total_Price,
          quantity: this.quantity,
        });
      } else {
        this.quantity = this.datahome.count;
        swl.fire({
          icon: "error",
          title: "Quantity cannot be more than available quantity",
          text: "Quantity cannot be more than available quantity",
        });
      }
    },
    decrement() {
      if (this.quantity > 1) {
        this.quantity -= 1;
        this.total_Price = this.quantity * this.price;
        this.$emit("update-data", {
          total_price: this.total_Price,
          quantity: this.quantity,
        });
      } else {
        swl.fire({
          icon: "error",
          title: "Quantity cannot be less than 1",
          text: "Quantity cannot be less than 1",
        });
      }
    },

    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
  computed: {
    modules() {
      return [FreeMode, Navigation, Thumbs];
    },
  },
};
</script>
